
// Libs
import { Vue, Options } from "vue-class-component";
import List, { ListOptions } from "list.js";
import Swal from "sweetalert2";
import { Tooltip } from "bootstrap";

// Plugins, Utils, Constants
import { languagesModule, classgroupsModule, exercisesModule } from "@/store";
import { Classgroup } from "@/store/modules/classgroups";

// Components
import Dropdown from "@/components/Utils/Dropdown.vue";
import DataTableExercises from "@/components/Dashboard/DataTableExercises.vue";
import SearchExercises from "@/components/SearchExercisesFilter/Index.vue";

@Options({
  name: "ClassgroupDashboard",
  components: {
    Dropdown,
    DataTableExercises,
    SearchExercises,
  },
})
export default class ClassgroupDashboard extends Vue {
  private windowWidth: number = window.innerWidth;
  private dataListOptions: ListOptions = {
    valueNames: ["code", "name", "level", "studentCount"],
    page: 5,
    pagination: {
      paginationClass: "pagination",
    },
  };
  private dataTable: any;
  private languagePair: any = null;

  $refs!: {
    classGroupCodeInfoTooltip: any;
  };

  async mounted(): Promise<void> {
    await classgroupsModule.getClassGroups();
    this.languagePair = this.languagePairs[0];
    await this.filterExercises();
    this.dataTable = new List("classgroups-data-table", this.dataListOptions);
    new Tooltip(this.$refs.classGroupCodeInfoTooltip, { placement: "top" });
    window.addEventListener("resize", this.handleResize);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(): void {
    this.windowWidth = window.innerWidth;
  }

  get languagePairs() {
    return languagesModule.languageMapping;
  }

  get classgroups(): Classgroup[] {
    return classgroupsModule.classGroups;
  }

  get showVerticalFilter(): boolean {
    return this.windowWidth > 1366;
  }

  get exercises() {
    return exercisesModule.exercises;
  }

  get exercisesPagination() {
    return exercisesModule.pagination;
  }

  async filterExercises(filters: any = null): Promise<void> {
    if (filters) {
      this.languagePair = filters.languagePair;
    }

    await exercisesModule.getExercises({
      languageFromId: this.languagePair.pair.languageFromId,
      languageToId: this.languagePair.pair.languageToId,
      queryParams: {
        offset: 0,
        limit: 8,
        sortBy: "PLAYLIST_NUMBER",
        sortDirection: "ASC",
      },
      filters: filters,
    });
  }

  createNewGroup(): void {
    this.$router.push({ name: "ClassgroupCreate" });
  }

  async deleteClassgroup(classgroup: Classgroup): Promise<void> {
    const { value: confirmDelete } = await Swal.fire({
      icon: "warning",
      title: `Are you sure you want to delete the classgroup: ${classgroup.name}?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ea5455",
    });

    if (confirmDelete) {
      await classgroupsModule.deleteClassgroup(classgroup.id);
      await classgroupsModule.getClassGroups();
      this.dataTable.reIndex();
    }
  }

  goToClassgroupExercises(classgroupID: number): void {
    this.$router.push({
      name: "ClassgroupExercises",
      params: {
        classgroupId: classgroupID,
      },
    });
  }
}
