import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxValue, (i) => {
      return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass([i <= _ctx.value ? 'icon-info' : 'text-300', "fa fa-star"]),
        key: i
      }, null, 2))
    }), 128))
  ]))
}