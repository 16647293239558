
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

interface Attrs {
  "id-collapsable": string;
  "data-bs-toggle": string;
  "data-bs-target": string;
  role: string;
  "aria-expanded": string;
  "aria-controls": string;
}

@Options({
  name: "MenuItem",
})
export default class MenuItem extends Vue {
  @Prop({ type: String })
  text!: string;
  @Prop({ type: String })
  icon!: string;
  @Prop({ type: String })
  href!: string;
  @Prop({ type: Array })
  children!: [];

  getAttrs() {
    if (this.children) {
      const text = this.text.trim().toLowerCase();
      const attrs: Attrs = {
        "id-collapsable": `${text}-menu`,
        "data-bs-toggle": "collapse",
        "data-bs-target": `#${text}-menu`,
        role: "button",
        "aria-expanded": "false",
        "aria-controls": `${text}-menu`,
      };

      return attrs;
    }

    return {};
  }
}
