
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "Ratings",
})
export default class Rating extends Vue {
  @Prop({ type: Number })
  value!: number;
  @Prop({ type: Number })
  maxValue!: number;
}
