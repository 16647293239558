import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { http } from "@/plugins/http";

export interface Language {
  id: number;
  code: string;
  name: string;
  level: LanguageLevel[];
}

export interface LanguageLevel {
  id: number;
  title: string;
  description: string;
  level: string;
  shortDescription?: string;
}

export interface LanguageMapping {
  id: number;
  name: string;
  description: string;
  pair: {
    languageFromId: number;
    languageFromName: string;
    languageToId: number;
    languageToName: string;
  };
}

const getShortDescription = (level: any): string => {
  return `${level.title} - ${level.description
    .split(" ")
    .slice(0, 2)
    .join(" ")}`;
};

@Module({ generateMutationSetters: true })
export default class LanguagesModule extends VuexModule {
  // state
  private _languages: Language[] = [];
  private levels: LanguageLevel[] = [];
  private mapping: LanguageMapping[] = [];

  // getters
  get languages(): Language[] {
    return this._languages;
  }

  get languageLevel(): LanguageLevel[] {
    return this.levels;
  }

  get languageMapping(): LanguageMapping[] {
    return this.mapping;
  }

  // mutations
  @Mutation
  public setLanguages(data: Language[]): void {
    this._languages = [...data];
  }

  @Mutation
  public setLevels(data: LanguageLevel[]): void {
    this.levels = data.map((level) => {
      level.shortDescription = getShortDescription(level);
      return level;
    });
  }

  @Mutation
  public setMapping(data: any): void {
    let idx = 1;
    data.forEach((pair: any) => {
      pair.available.forEach((availableLanguage: any) => {
        this.mapping.push({
          id: idx,
          name: `${pair.from.name} - ${availableLanguage.name}`,
          description: `Knows: ${pair.from.name}. Learns: ${availableLanguage.name}.`,
          pair: {
            languageFromId: pair.from.id,
            languageFromName: pair.from.name,
            languageToId: availableLanguage.id,
            languageToName: availableLanguage.name,
          },
        });
        idx++;
      });
    });
  }

  // actions
  @Action
  public async initLanguages(): Promise<void> {
    try {
      if (this.languages.length === 0) await this.getLanguages();
      if (this.languageLevel.length === 0) await this.getLanguagesLevel();
      if (this.languageMapping.length === 0) await this.getLanguagesMapping();
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getLanguages(): Promise<void> {
    try {
      const resp = await http.get("/system/languages");
      return this.setLanguages(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getLanguagesLevel(): Promise<void> {
    try {
      const resp = await http.get(
        `system/strings/${this.languages[0].id}/profile/language/level/v2`
      );
      return this.setLevels(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getLanguagesMapping(): Promise<void> {
    try {
      const resp = await http.get("/system/languages/mappings");
      return this.setMapping(resp.data);
    } catch (error) {
      console.error(error);
    }
  }
}
