// @ts-nocheck
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocation,
  NavigationGuardNext,
} from "vue-router";
import { authModule, languagesModule } from "@/store";
import i18n, {
  SUPPORT_LOCALES,
  setI18nLanguage,
  loadLocaleMessages,
} from "@/i18n";
import { NO_SIDEBAR } from "@/layout/constants";

const PUBLIC_ROUTES = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: NO_SIDEBAR },
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: {
      async beforeRouteEnter(
        _to: RouteLocation,
        _from: RouteLocation,
        next: NavigationGuardNext
      ): void {
        await authModule.logout();
        next({ name: "Login" });
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    meta: { layout: NO_SIDEBAR },
    component: () => import("@/views/Auth/Register.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: { layout: NO_SIDEBAR },
    component: () => import("@/views/Auth/ForgotPassword.vue"),
  },
];

const PRIVATE_ROUTES = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Classgroup/ClassgroupList.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: { breadcrumbs: ["Profile"] },
  },
  {
    path: "/classgroup",
    name: "Classgroups",
    component: () => import("@/views/Classgroup/ClassgroupList.vue"),
  },
  {
    path: "/classgroup/create",
    name: "ClassgroupCreate",
    component: () => import("@/views/Classgroup/ClassgroupCreate.vue"),
    meta: { breadcrumbs: ["Classgroups", "Create"] },
  },
  {
    path: "/classgroup/:classgroupId/",
    name: "ClassgroupDetails",
    component: () => import("@/views/Classgroup/ClassgroupDetails.vue"),
    meta: { breadcrumbs: ["Classgroups", "Details"] },
  },
  {
    path: "/classgroup/:classgroupId/exercises",
    name: "ClassgroupExercises",
    component: () => import("@/views/Classgroup/ClassgroupExercises.vue"),
    meta: { breadcrumbs: ["Classgroups", "Exercises"] },
  },
  {
    path: "/classgroup/:classgroupId/students",
    name: "ClassgroupStudentsCreate",
    component: () => import("@/views/Classgroup/ClassgroupStudentsCreate.vue"),
    meta: { breadcrumbs: ["Classgroups"] },
  },
  {
    path: "/classgroup/:classgroupId/students/:studentId",
    name: "StudentsDetails",
    component: () => import("@/views/Student/StudentDetails.vue"),
    meta: { breadcrumbs: ["Classgroups", "ClassgroupExercises", "Students"] },
  },
  {
    path: "/exercises",
    name: "Exercises",
    component: () => import("@/views/Exercises/Index.vue"),
    meta: { breadcrumbs: ["Exercises"] },
  },
];

export const routes: Array<RouteRecordRaw> = [
  ...PRIVATE_ROUTES,
  ...PUBLIC_ROUTES,
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// TODO: Refactor using middlewares folder

const isPublicRoute = (to): boolean => {
  return PUBLIC_ROUTES.some((route) => route.name === to.name);
};

const shouldRedirectToLogin = (to): boolean => {
  return !isPublicRoute(to) && !authModule.isLoggedIn;
};

router.beforeEach(
  async (
    to: RouteLocation,
    _from: RouteLocation,
    next: NavigationGuardNext
  ) => {
    if (shouldRedirectToLogin(to)) {
      next({ name: "Login" });
    } else {
      let locale = localStorage.getItem("preferredLanguage");

      if (!SUPPORT_LOCALES.includes(locale)) {
        locale = "en";
      }

      if (!i18n.global.availableLocales.includes(locale)) {
        await loadLocaleMessages(i18n, locale);
      }

      setI18nLanguage(i18n, locale);

      if (!isPublicRoute(to)) {
        await languagesModule.initLanguages();
      }

      next();
    }
  }
);

export default (app) => {
  app.router = router;
  app.use(router);
};
