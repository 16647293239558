
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class FormatDate extends Vue {
  @Prop()
  public dateToFormat!: string;

  formatDate(date: string): string { 
    var datum = new Date(date);
    // TODO handle proper locale for user
    return new Intl.DateTimeFormat("cs-CZ").format(datum);
  }
}
