
import { Vue, Options } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

import SearchExercisesHorizontal from "@/components/SearchExercisesFilter/SearchExercisesFilterHorizontal.vue";
import SearchExercisesVertical from "@/components/SearchExercisesFilter/SearchExercisesFilterVertical.vue";

@Options({
  name: "SearchExercises",
  components: {
    SearchExercisesHorizontal,
    SearchExercisesVertical,
  },
})
export default class SearchExercises extends Vue {
  @Prop({ type: Boolean })
  private horizontal = false;

  @Prop({ type: Boolean })
  private vertical = false;
}
