import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { http } from "@/plugins/http";

export interface Assignment {
  id: number;
  availableFrom: string;
  deadline: string;
  notesForMe: Date;
  notesForStudents: string;
  exerciseId: number;
  classGroupId: number;
}

interface Pagination {
  limit: number;
  offset: number;
  count: number;
}

@Module({ generateMutationSetters: true })
export default class AssignmentsModule extends VuexModule {
  private assignments: Assignment[] = [];
  private isLoading = false;
  private _pagination: Pagination = {
    limit: 8,
    offset: 0,
    count: 0,
  };

  get assigments(): Array<Assignment> {
    return this.assignments;
  }

  get assignmentsForClassgroup() {
    return (classGroupId: number): Assignment[] =>
      this.assignments.filter(
        (assignment) => assignment.classGroupId === classGroupId
      );
  }

  get assignmentsLoading(): boolean {
    return this.isLoading;
  }

  get pagination(): Pagination {
    return this._pagination;
  }

  @Mutation
  public setAssignments(assignments: Assignment[]): void {
    this.assignments = [...assignments];
  }

  @Mutation
  public setPagination({ limit, offset, count }: Pagination): void {
    this._pagination = {
      limit: limit,
      offset: offset,
      count: count,
    };
  }

  @Action
  public async fetchAssignmentsForClassgroup(
    classGroupId: number
  ): Promise<void> {
    this.isLoading = true;
    try {
      const response = await http.get(
        `/teachers/classgroups/${classGroupId}/assignments`,
        { params: { ...this._pagination } }
      );
      this.isLoading = false;
      this.setAssignments(response.data.assignments);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getAssignments(value: {
    classGroupId: number;
    queryParams: {
      offset: number;
      limit: number;
      sortBy: string;
      sortDirection: string;
    };
  }): Promise<void> {
    const params: any = { params: { ...value.queryParams } };
    try {
      const resp = await http.get(
        `/teachers/classgroups/${value.classGroupId}/assignments`,
        params
      );
      //TODO use also other props - count, offset, limit
      // https://staging.api.latudio.com/api/1.0/swagger-ui#/TeacherClassgroupAssignments/getAssignmentsS
      return this.setAssignments(resp.data.assignments);
    } catch (error) {
      console.error(error);
    }
  }
}
