
import { Vue } from "vue-class-component";
import { FormLogin, ResponseError } from "./login";

export default class ForgotPassword extends Vue {
  form: any = {
    body: {
      email: "",
      password: "",
    },
    rememberMe: false,
  };
  fetchUser = true;
  staySignedIn = true;
  errors = {};

  processForm(): void {
    // TODO: Call authentication service - generate code
    console.log("Generating code");
  }

  processFormCode(): void {
    // TODO: Call authentication service - validate code
  }

  parseErrors(resp: ResponseError): void {
    this.errors = Object.fromEntries(
      resp.data.errors.map((item) => [item.field, item.msg])
    );
  }
}
