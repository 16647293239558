
import { Options, Vue } from "vue-class-component";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";
import Input from "@/components/Utils/Input.vue";

@Options({
  name: "Profile",
  components: {
    Field,
    Form,
    Input,
  },
})
export default class Profile extends Vue {
  schema = yup.object({
    title: yup.string().optional(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required().email(),
    accountType: yup.string().required(),
  });
  passwordSchema = yup.object({
    password: yup.string().min(8),
    confirmPassword: yup
      .string()
      .min(8)
      .oneOf([yup.ref("password")], "Password do not match"),
  });
  errors = {};

  processForm(values: any): void {
    // TODO: Call profile service - Update info
    this.showModal();
  }

  processPasswordForm(values: any): void {
    // TODO: Call auth service - Change password
  }

  showModal(): void {
    Swal.fire({
      title: "Congratulations!",
      text: "Your profile has been successfully updated.",
      icon: "success",
      confirmButtonColor: "#00d27a",
    });
  }
}
