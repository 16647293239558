
import { Options, Vue } from "vue-class-component";

import { NO_SIDEBAR } from "@/layout/constants";
import Default from "@/layout/Default.vue";
import NoSidebar from "@/layout/NoSidebar.vue";

@Options({
  name: "App",
  components: {
    Default,
    NoSidebar,
  },
})
export default class App extends Vue {
  get layout() {
    if (this.$route.meta.layout == NO_SIDEBAR) {
      return NoSidebar;
    }

    return Default;
  }
}
