
import { Options, Vue } from "vue-class-component";
import Header from "@/layout/Header.vue";
import Sidebar from "@/layout/Sidebar.vue";
import Footer from "@/layout/Footer.vue";

@Options({
  components: {
    Header,
    Sidebar,
    Footer,
  },
})
export default class Default extends Vue {}
