import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row position-absolute bottom-50 end-50" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = {
  ref: "loadingContainer",
  class: "lottie mx-auto",
  style: {"width":"120px","height":"120px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, null, 512)
    ])
  ]))
}