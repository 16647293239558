
import { Options, Vue } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

@Options({
  name: "TogglerSidebar",
})
export default class TogglerSidebar extends Vue {
  @Prop({ type: Boolean })
  private toggled!: boolean;

  @Emit("toggleMenu")
  toggleMenu(): boolean {
    return this.toggled;
  }
}
