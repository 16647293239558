
import { Options, Vue } from "vue-class-component";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";

import { classgroupsModule } from "@/store";
import Input from "@/components/Utils/Input.vue";
import Loading from "@/components/Utils/Loading.vue";

@Options({
  name: "ClassgroupDetails",
  components: {
    Field,
    Form,
    Input,
    Loading,
  },
})
export default class ClassgroupDetails extends Vue {
  schema = yup.object({
    name: yup.string(),
    email: yup.string(),
  });
  errors = {};
  private student: any;
  private loading = true;

  async mounted(): Promise<void> {
    this.loading = true;
    const classgroupId = this.$route.params.classgroupId;
    const studentId = this.$route.params.studentId;
    await classgroupsModule.getClassGroup(classgroupId);
    await classgroupsModule.getClassGroupStudent({
      classgroupId: classgroupId,
      studentId: studentId,
    });
    this.student = classgroupsModule.student;
    this.loading = false;
  }

  backToClassGroupList(): void {
    classgroupsModule.clearStudent();
    this.$router.push({
      name: "ClassgroupExercises",
      params: {
        id: this.student.classGroupId,
      },
    });
  }

  get classGroup(): any {
    return classgroupsModule.classGroup;
  }

  processForm(value: any): void {
    const payload = {
      classgroupId: this.classGroup.id,
      studentId: this.student.id,
      data: {
        ...value,
      },
    };
    // classgroupsModule.editClassgroupStudent(payload).then(() => {
    //   this.showModal();
    // });
  }

  showModal(): void {
    Swal.fire({
      title: "Congratulations!",
      text: "Your student has been successfully updated.",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#00d27a",
    }).then(() => {
      this.$router.push(`/classgroup/${this.classGroup.id}/exercises`);
    });
  }
}
