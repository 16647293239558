
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import List, { ListOptions } from "list.js";

import { classgroupsModule } from "@/store";
import { Student } from "@/store/modules/classgroups";

import ModalCreateStudent from "@/components/Dashboard/ModalCreateStudent.vue";
import Dropdown from "@/components/Utils/Dropdown.vue";
import Loading from "@/components/Utils/Loading.vue";

@Options({
  name: "DataTableStudents",
  components: {
    ModalCreateStudent,
    Dropdown,
    Loading,
  },
})
export default class DataTableStudents extends Vue {
  @Prop()
  private classgroup: any;

  private dataListOptions: ListOptions = {
    valueNames: ["name", "comments", "badges_earned", "words"],
    page: 5,
    pagination: {
      paginationClass: "pagination",
    },
  };
  private students: any = [];
  private loading = true;
  private dataTable: any;
  private searchText = "";

  $refs!: {
    modalCreateStudentContainer: any;
  };

  async mounted(): Promise<void> {
    this.loading = true;
    await classgroupsModule.getClassGroupStudents(this.classgroup.id);
    this.students = classgroupsModule.students;
    this.loading = false;
  }

  updated(): void {
    this.dataTable = new List(
      "classgroups-students-data-table",
      this.dataListOptions
    );
  }

  createNewGroup(): void {
    this.$router.push("/classgroup/create");
  }

  addNewStudent(): void {
    const modal = Modal.getOrCreateInstance(
      this.$refs.modalCreateStudentContainer.$el
    );
    modal.show();
  }

  async deleteClassgroupStudent(student: any): Promise<void> {
    const { value: confirmDelete } = await Swal.fire({
      icon: "warning",
      title: `Are you sure you want to delete the student: ${student.name}?`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ea5455",
    });

    if (confirmDelete) {
      await classgroupsModule.deleteStudent(student);
      await classgroupsModule.getClassGroupStudents(student.classGroupId);
      this.students = classgroupsModule.students;
      this.dataTable.reIndex();
    }
  }

  closeStudentModal(): void {
    const modal = Modal.getOrCreateInstance(
      this.$refs.modalCreateStudentContainer.$el
    );
    modal.hide();
  }
}
