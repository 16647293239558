
import { Options, Vue } from "vue-class-component";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";
// @ts-expect-error
import { SmartTagz } from "smart-tagz";

import { languagesModule } from "@/store";
import { classgroupsModule } from "@/store";
import Input from "@/components/Utils/Input.vue";
import "smart-tagz/dist/smart-tagz.css";

@Options({
  name: "ClassgroupDetails",
  components: {
    Field,
    Form,
    Input,
    SmartTagz,
  },
})
export default class ClassgroupDetails extends Vue {
  schema = yup.object({
    name: yup.string().required("You must input a classgroup name."),
    language: yup.number(),
    // languageLevelId: yup.number(),
  });
  errors = {};
  students: Array<string> = [];

  async created(): Promise<void> {
    const classgroupId = this.$route.params.classgroupId;
    await classgroupsModule.getClassGroup(classgroupId);
  }

  get languages() {
    return languagesModule.languageMapping;
  }

  get languageLevels() {
    return languagesModule.languageLevel;
  }

  get classGroup(): any {
    return classgroupsModule.classGroup;
  }

  get classGroupLanguageMapping() {
    return this.classGroup?.languageMapping?.id;
  }

  addStudents(students: string[]): void {
    this.students = students;
  }

  processForm(value: any): void {
    const payload = {
      classgroupId: this.classGroup.id,
      data: {
        name: value.name,
        languageLevelId: value.languageLevelId,
        ...this.languages[value.language - 1].pair,
      },
    };
    classgroupsModule.editClassgroup(payload).then(() => {
      this.showModal();
    });
  }

  showModal(): void {
    Swal.fire({
      title: "Congratulations!",
      text: "Your class group has been successfully updated.",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#00d27a",
    }).then(() => {
      this.$router.push("/classgroup");
    });
  }
}
