
import { Vue, Options } from "vue-class-component";

import { classgroupsModule, exercisesModule } from "@/store";

import DataTableStudents from "@/components/Dashboard/DataTableStudents.vue";
import DataTableExercises from "@/components/Dashboard/DataTableExercises.vue";
import DataTableAssignments from "@/components/Dashboard/DataTableAssignments.vue";
import SearchExercises from "@/components/SearchExercisesFilter/Index.vue";
import Loading from "@/components/Utils/Loading.vue";

@Options({
  name: "ClassgroupDashboard",
  components: {
    DataTableStudents,
    DataTableAssignments,
    DataTableExercises,
    SearchExercises,
    Loading,
  },
})
export default class ClassgroupExercises extends Vue {
  private windowWidth: number = window.innerWidth;
  private classGroup: any;
  private loading = true;

  get classGroups() {
    return classgroupsModule.classGroups;
  }

  get exercises() {
    return exercisesModule.exercises;
  }

  get pagination() {
    return exercisesModule.pagination;
  }

  get showVerticalFilter(): boolean {
    return this.windowWidth > 1366;
  }

  async mounted(): Promise<void> {
    this.loading = true;
    await classgroupsModule.getClassGroups();
    const classGroupId = this.$route.params.classgroupId;
    await classgroupsModule.getClassGroup(classGroupId);
    this.classGroup = classgroupsModule.classGroup;
    await this.filterExercises();
    this.loading = false;
    window.addEventListener("resize", this.handleResize);
  }

  async filterExercises(filters: any = null): Promise<void> {
    await exercisesModule.getExercises({
      languageFromId: this.classGroup.languageFromId,
      languageToId: this.classGroup.languageToId,
      queryParams: {
        offset: 0,
        limit: 8,
        sortBy: "PLAYLIST_NUMBER",
        sortDirection: "ASC",
      },
      filters: filters,
    });
  }

  destroyed(): void {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(): void {
    this.windowWidth = window.innerWidth;
  }

  createNewGroup(): void {
    this.$router.push("/classgroup/create");
  }

  addNewStudent(groupId: number): void {
    this.$router.push(`/classgroup/${groupId}/student/create`);
  }
}
