export default {
  "menu": {
    "classgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Classgroups"])},
    "my-exercises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Assignments"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "payment-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "subscribers-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribers/Contacts"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "authentication": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "do-not-have-an-account-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account yet?"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "remember-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])}
  },
  "register": {
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business or School"])},
    "accept-terms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I have read and agree to the ", _interpolate(_list(0)), " and our ", _interpolate(_list(1)), "."])},
    "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "tp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking Policy"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term and Conditions"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "already-have-an-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password-do-not-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])}
  },
  "common": {
    "forms": {
      "field-is-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])}
    }
  }
}