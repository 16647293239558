
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "Dropdown",
})
export default class Dropdown extends Vue {
  @Prop({ type: String })
  id!: string;

  @Prop({ type: Boolean })
  disabled!: boolean;
}
