<template>
  <div class="row">
    <div class="col">
      <span class="align-items-center aling-middle fs">
        <strong>Filters:</strong>
      </span>
    </div>
  </div>
  <div class="mt-2 d-flex flex-md-row justify-content-evenly">
    <div class="row">
      <div v-if="languagePairs && languagePairs.length > 0" class="col">
        <select
          id="filter-language-pair"
          class="my-0 form-select fs--1"
          v-model="filterModel.languagePair"
        >
          <option
            v-for="languagePair in languagePairs"
            :key="languagePair.id"
            v-text="languagePair.name"
            :value="languagePair"
          />
        </select>
      </div>

      <div class="col">
        <div class="btn-group">
          <button
            class="btn btn-falcon-primary btn-table-header dropdown-toggle"
            type="button"
            id="exerciseType"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
          >
            Assignment Type
          </button>
          <ul class="dropdown-menu search-list" aria-labelledby="Bulk actions">
            <li v-for="(exerciseType, idx) in exercisesType" :key="idx">
              <div class="dropdown-item">
                <input
                  class="form-check-input"
                  :id="`filter-assignment-type-${idx}`"
                  type="checkbox"
                  v-model="filterModel.exerciseType"
                  :value="exerciseType.value"
                />
                <label
                  class="form-check-label mb-0"
                  :for="`filter-assignment-type-${idx}`"
                  v-text="exerciseType.name"
                ></label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col">
        <div class="btn-group">
          <button
            class="btn btn-falcon-primary btn-table-header dropdown-toggle px-5"
            type="button"
            id="level"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
          >
            Levels
          </button>
          <ul class="dropdown-menu search-list" aria-labelledby="Bulk actions">
            <li v-for="(level, idx) in levels" :key="idx">
              <div class="dropdown-item">
                <input
                  class="form-check-input me-1"
                  :id="`filter-level-${idx}`"
                  type="checkbox"
                  v-model="filterModel.level"
                  :value="level.id"
                />
                <label
                  class="form-check-label mb-0"
                  :for="`filter-level-${idx}`"
                  v-text="level.shortDescription"
                ></label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col">
        <div class="btn-group">
          <button
            class="btn btn-falcon-primary btn-table-header dropdown-toggle px-4"
            type="button"
            id="word"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
          >
            Word of type
          </button>
          <ul class="dropdown-menu search-list" aria-labelledby="Bulk actions">
            <li v-for="(wordClass, idx) in wordClasses" :key="idx">
              <div class="dropdown-item">
                <input
                  class="form-check-input me-1"
                  :id="`filter-word-type-${idx}`"
                  type="checkbox"
                  v-model="filterModel.wordClass"
                  :value="wordClass.toUpperCase()"
                />
                <label
                  class="form-check-label mb-0"
                  :for="`filter-word-type-${idx}`"
                  v-text="wordClass"
                ></label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col">
        <div class="btn-group">
          <button
            class="btn btn-falcon-primary btn-table-header dropdown-toggle px-4"
            type="button"
            id="category"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
          >
            Categories
          </button>
          <ul class="dropdown-menu search-list" aria-labelledby="Bulk actions">
            <li v-for="category in categories" :key="category.id">
              <div class="dropdown-item">
                <input
                  class="form-check-input me-1"
                  :id="`filter-category-${category.id}`"
                  type="checkbox"
                  v-model="filterModel.category"
                  :value="category.id"
                />
                <label
                  class="form-check-label mb-0"
                  :for="`filter-category-${category.id}`"
                  v-text="category.name"
                ></label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col">
        <input
          class="form-control btn-table-header w-100"
          type="text"
          id="word"
          placeholder="Search for a word or phrase"
          v-model="filterModel.searchText"
        />
      </div>

      <div class="col pe-1">
        <button
          class="btn btn-sm btn-primary ml-4 btn-table-header float-end"
          @click="filter"
        >
          <span class="bi-search" /> Search Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, mixins } from "vue-class-component";

import { SearchExercisesFilterProps } from "@/components/SearchExercisesFilter/mixins";

@Options({
  name: "SearchExercisesFilterHorizontal",
})
export default class SearchExercisesFilterHorizontal extends mixins(
  SearchExercisesFilterProps
) {}
</script>
