import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { http } from "@/plugins/http";
import axios from "axios";
import { languagesModule } from "@/store";
import { LanguageMapping } from "@/store/modules/languages";

export interface ClassgroupBase {
  name: string;
  languageFromId: number;
  languageToId: number;
}

export interface Classgroup extends ClassgroupBase {
  id: number;
  code: string;
  language: number;
  studentCount: number;
  languageMapping: LanguageMapping;
}

export interface Student {
  id: number;
  classGroupId: number;
  name: string;
  email: string;
  comments: number;
  badgesEarned: number;
  words: number;
  assignmentsTotal: number;
  assignmentsCompleted: number;
}

const findLanguagePair = (
  languageFromId: number,
  languageToId: number
): LanguageMapping => {
  const languagePair = languagesModule.languageMapping.find(
    ({ pair }) =>
      pair.languageFromId === languageFromId &&
      pair.languageToId === languageToId
  ) as LanguageMapping;

  return languagePair;
};

@Module({ generateMutationSetters: true })
export default class ClassgroupsModule extends VuexModule {
  // state
  private state: Classgroup[] = [];
  private _classGroup = {} as Classgroup;
  private _students: Student[] = [];
  private _student = {} as Student;

  // getters
  get classGroups(): Classgroup[] {
    return this.state;
  }

  get classGroup(): Classgroup {
    return this._classGroup;
  }

  get students(): Student[] {
    return this._students;
  }

  get student(): Student {
    return this._student;
  }

  // mutations
  @Mutation
  public setClassgroups(data: Classgroup[]): void {
    const classgroups = data.map((classgroup: Classgroup) => {
      classgroup.languageMapping = findLanguagePair(
        classgroup.languageFromId,
        classgroup.languageToId
      );

      return classgroup;
    });
    this.state = [...classgroups];
  }

  @Mutation
  public setClassgroup(classgroup: Classgroup): void {
    const languagePair = findLanguagePair(
      classgroup.languageFromId,
      classgroup.languageToId
    );
    this._classGroup = {
      ...classgroup,
      languageMapping: languagePair,
      language: languagePair.id,
    };
  }

  @Mutation
  public setClassgroupStudents(students: Student[]): void {
    this._students = [...students];
  }

  @Mutation
  public setClassgroupStudent(student: Student): void {
    this._student = { ...student };
  }

  @Mutation
  public clearClassgroupStudent() {
    this._student = {} as Student;
  }

  // actions
  @Action
  public async getClassGroups(): Promise<void> {
    try {
      const resp = await http.get("/teachers/classgroups");
      return this.setClassgroups(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getClassGroup(classgroupId: any): Promise<void> {
    try {
      const resp = await http.get(`/teachers/classgroups/${classgroupId}`);
      return this.setClassgroup(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async createClassgroup(data: ClassgroupBase): Promise<void> {
    try {
      return http.post("/teachers/classgroups", data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async editClassgroup(value: {
    classgroupId: any;
    data: any;
  }): Promise<void> {
    try {
      const resp = await http.patch(
        `/teachers/classgroups/${value.classgroupId}`,
        value.data
      );
      this.setClassgroups(resp.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  @Action
  public async deleteClassgroup(classgroupId: any): Promise<void> {
    try {
      await http.delete(`/teachers/classgroups/${classgroupId}`);
    } catch (error) {
      console.log(error);
    }
  }

  @Action
  public async getClassGroupStudents(classgroupId: any): Promise<void> {
    try {
      const resp = await http.get(
        `/teachers/classgroups/${classgroupId}/students`
      );
      return this.setClassgroupStudents(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getClassGroupStudent(value: any): Promise<void> {
    try {
      const resp = await http.get(
        `/teachers/classgroups/${value.classgroupId}/students/${value.studentId}`
      );
      return this.setClassgroupStudent(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async createStudent(value: {
    classgroupId: any;
    student: { name: string; email: string };
  }): Promise<void> {
    try {
      await http.post(
        `/teachers/classgroups/${value.classgroupId}/invitations`,
        value.student
      );
    } catch (error: any) {
      console.error(error);
      throw Error(error);
    }
  }

  @Action
  public async inviteStudentByEmail(value: {
    classgroupId: any;
    email: string;
  }): Promise<void> {
    try {
      await http.post(
        `/teachers/classgroups/${value.classgroupId}/invitations`,
        { email: value.email }
      );
    } catch (error: any) {
      console.error(error);
      throw Error(error);
    }
  }

  @Action
  public async createStudents(value: {
    classgroupId: any;
    students: any;
  }): Promise<{ success: boolean }> {
    try {
      const requests = value.students.map((student: any) => {
        return this.createStudent({
          classgroupId: value.classgroupId,
          student: student,
        });
      });
      await axios.all(requests);

      return Promise.resolve({ success: true });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ success: false });
    }
  }

  @Action
  public async inviteStudentsByEmail(value: {
    classgroupId: any;
    emails: any;
  }): Promise<{ success: boolean }> {
    try {
      const requests = value.emails.map((email: any) => {
        return this.inviteStudentByEmail({
          classgroupId: value.classgroupId,
          email: email,
        });
      });
      await axios.all(requests);

      return Promise.resolve({ success: true });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ success: false });
    }
  }

  @Action
  public async deleteStudent(value: Student): Promise<void> {
    try {
      await http.delete(
        `/teachers/classgroups/${value.classGroupId}/students/${value.id}`
      );
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public clearStudent(): void {
    this.clearClassgroupStudent();
  }
}
