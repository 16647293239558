import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import axios from "axios";
import { http } from "@/plugins/http";
import { languagesModule } from "@/store";
import { LanguageLevel, LanguageMapping } from "@/store/modules/languages";

export interface Exercise {
  id: number;
  difficult: number;
  type: string;
  level: string;
  gender: string;
  accent: string;
  assigned: boolean;
  liked: number;
  notes: Array<string>;
  availableInApp: string;
  completationDeadline: string;
}

interface ExerciseAPIResponse {
  id: number;
  difficultyId: number;
  playlistOrdinal: number;
  questionType: string;
  type: string;
}

interface ExerciseAssignedAPIResponse {
  availableFrom: string;
  classGroupId: number;
  deadline: string;
  exerciseId: number;
  id: number;
  notesForMe: string;
  notesForStudents: string;
}

interface ExerciseCategoryAPIResponse {
  id: number;
  name: string;
}

const findLanguageLevel = (languageLevelId: number): LanguageLevel => {
  const languageLevel = languagesModule.languageLevel.find(
    (level: any) => level.id === languageLevelId
  ) as LanguageLevel;

  return languageLevel;
};

const buildFilters = (filters: any) => {
  const data: any = {};

  if (filters.category.length) {
    data["categoryIds"] = [...filters.category];
  }

  if (filters.exerciseType.length) {
    data["exerciseTypes"] = [...filters.exerciseType];
  }

  if (filters.level.length) {
    data["difficultyIds"] = [...filters.level];
  }

  if (filters.wordClass.length) {
    data["wordTypes"] = [...filters.wordClass];
  }

  if (filters.searchText !== "") {
    data["searchText"] = filters.searchText;
  }

  return data;
};

const EXERCISE_CONTENT: any = {
  LISTENING: "messages",
  CITY_LIFE: "memorypractice",
  BUDDY_TALK: "buddytalk",
};

@Module({ generateMutationSetters: true })
export default class ExercisesModule extends VuexModule {
  // state
  private _state: Array<Exercise> = [];
  private _assignedExercises: any[] = [];
  private _categories: any[] = [];
  private _pagination = {
    limit: 8,
    offset: 0,
    count: 0,
  };

  // getters
  get exercises(): Array<Exercise> {
    return this._state;
  }

  get assignedExercises() {
    return this._assignedExercises;
  }

  get exerciseCategories() {
    return this._categories;
  }

  get exerciseWordClasses() {
    return [];
  }

  get pagination() {
    return this._pagination;
  }

  // TODO - handle missing exercise from local store (should fetch it)
  public getExerciseById(id: number): Exercise {
    return this._state.filter( exercise => exercise.id === id)[0]
  }
  // mutations
  @Mutation
  public setExercises(value: ExerciseAPIResponse[]): void {
    this._state = value.map((exercise: ExerciseAPIResponse) => {
      return {
        id: exercise.id,
        difficult: exercise.difficultyId,
        type: exercise.type,
        level: findLanguageLevel(exercise.difficultyId).title,
        gender: "M",
        accent: "US English",
        assigned: false,
        liked: 5,
        notes: [],
        availableInApp: "",
        completationDeadline: "",
      } as Exercise;
    });
  }

  @Mutation
  public setAssignedExercises(value: ExerciseAssignedAPIResponse[]): void {
    this._assignedExercises = [...value];
  }

  @Mutation
  public setExerciseCategories(value: ExerciseCategoryAPIResponse[]): void {
    this._categories = [...value];
  }

  @Mutation
  public setPagination({ limit, offset, count }: any): void {
    this._pagination = {
      limit: limit,
      offset: offset,
      count: count,
    };
  }

  @Mutation
  public addExercise(value: Exercise): void {
    this._state.push(value);
  }

  @Mutation
  public assignExercises(value: number[]): void {
    value.forEach((exercise_id) => {
      this._state[exercise_id].assigned = true;
    });
  }

  // actions
  @Action
  public async getExercises(value: {
    languageFromId: number;
    languageToId: number;
    queryParams: {
      offset: number;
      limit: number;
      sortBy: string;
      sortDirection: string;
    };
    filters?: any;
  }): Promise<void> {
    try {
      let params: any = { params: { ...value.queryParams } };

      if (value.filters) {
        params = { ...params, data: buildFilters(value.filters) };
      }

      const resp = await http.request({
        method: "post",
        url: `/teachers/exercises/${value.languageFromId}/${value.languageToId}`,
        ...params,
      });

      this.setPagination(resp.data);

      return this.setExercises(resp.data.exercises);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getAssignedExercises(classgroupId: any) {
    try {
      const resp = await http.get(
        `/teachers/classgroups/${classgroupId}/assignments`
      );
      return this.setAssignedExercises(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getExerciseDetails(value: {
    languageFromId: any;
    languageToId: any;
    exercise: any;
  }) {
    const exerciseType = EXERCISE_CONTENT[value.exercise.type];
    try {
      const resp = await http.get(
        `/teachers/exercises/${value.languageFromId}/${value.languageToId}/content/${exerciseType}/${value.exercise.id}`
      );
      return resp.data;
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async getExerciseCategories(languageId: number): Promise<void> {
    try {
      const resp = await http.get("/teachers/exercises/categories", {
        params: { languageId: languageId },
      });
      return this.setExerciseCategories(resp.data);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  public async add(value: Exercise): Promise<void> {
    this.addExercise(value);
  }

  @Action
  public async prepareExercises(value: {
    classgroups: any;
    exercises: any;
    availableFrom: string;
    deadline: string;
  }): Promise<{ success: boolean }> {
    try {
      const requests: any = [];
      value.classgroups.forEach((classgroup: any) => {
        value.exercises.forEach((exerciseId: number) => {
          requests.push(
            this.assign({
              classgroupId: classgroup.value,
              exerciseId: exerciseId,
              availableFrom: value.availableFrom,
              deadline: value.deadline,
            })
          );
        });
      });

      await axios.all(requests);

      return Promise.resolve({ success: true });
    } catch (error) {
      console.error(error);
      return Promise.resolve({ success: false });
    }
  }

  @Action
  public async assign(value: {
    classgroupId: number;
    exerciseId: number;
    availableFrom: string;
    deadline: string;
  }) {
    try {
      return http.post(
        `/teachers/classgroups/${value.classgroupId}/assignments`,
        {
          exerciseId: value.exerciseId,
          availableFrom: `${value.availableFrom}T00:00:00.000Z`,
          deadline: `${value.deadline}T23:59:59.999Z`,
          notesForMe: "",
          notesForStudents: "",
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
}
