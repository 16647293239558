
import { Vue, Options } from "vue-class-component";

import { Tooltip } from "bootstrap";
import { classgroupsModule, exercisesModule } from "@/store";

import SearchExercises from "@/components/SearchExercisesFilter/Index.vue";
import DataTableExercises from "@/components/Dashboard/DataTableExercises.vue";
import Rating from "@/components/Utils/Rating.vue";

@Options({
  name: "Exercises",
  components: {
    SearchExercises,
    DataTableExercises,
    Rating,
  },
})
export default class Exercises extends Vue {
  $refs!: {
    assignamentInfoTooltip: any;
  };

  get classGroups() {
    return classgroupsModule.classGroups;
  }

  get classGroup() {
    return classgroupsModule.classGroup;
  }

  get exercises() {
    return exercisesModule.exercises;
  }

  get pagination() {
    return exercisesModule.pagination;
  }

  async created() {
    await classgroupsModule.getClassGroups();
    await classgroupsModule.getClassGroup(this.classGroups[0].id);
    await exercisesModule.getExercises({
      languageFromId: this.classGroup.languageFromId,
      languageToId: this.classGroup.languageToId,
      queryParams: {
        offset: 0,
        limit: 8,
        sortBy: "PLAYLIST_NUMBER",
        sortDirection: "ASC",
      },
    });
  }

  async getExercises(offset: number): Promise<void> {
    await exercisesModule.getExercises({
      languageFromId: this.classGroup.languageFromId,
      languageToId: this.classGroup.languageToId,
      queryParams: {
        offset: offset,
        limit: 8,
        sortBy: "PLAYLIST_NUMBER",
        sortDirection: "ASC",
      },
    });
  }

  async filterExercises(filters: any): Promise<void> {
    await exercisesModule.getExercises({
      languageFromId: this.classGroup.languageFromId,
      languageToId: this.classGroup.languageToId,
      queryParams: {
        offset: 0,
        limit: 8,
        sortBy: "PLAYLIST_NUMBER",
        sortDirection: "ASC",
      },
      filters: filters,
    });
  }

  mounted(): void {
    new Tooltip(this.$refs.assignamentInfoTooltip, { placement: "top" });
  }
}
