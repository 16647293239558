import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_exercises_horizontal = _resolveComponent("search-exercises-horizontal")!
  const _component_search_exercises_vertical = _resolveComponent("search-exercises-vertical")!

  return (_ctx.horizontal)
    ? (_openBlock(), _createBlock(_component_search_exercises_horizontal, { key: 0 }))
    : (_ctx.vertical)
      ? (_openBlock(), _createBlock(_component_search_exercises_vertical, { key: 1 }))
      : _createCommentVNode("", true)
}