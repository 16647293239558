
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Field } from "vee-validate";

@Options({
  name: "Input",
  components: {
    Field,
  },
})
export default class Input extends Vue {
  @Prop({ type: String })
  id!: string;
  @Prop({ type: String })
  classes!: string;
  @Prop({ type: String, default: "text" })
  type!: string;
  @Prop({ type: String })
  placeholder!: string;
  @Prop({ type: String })
  name!: string;
  @Prop({ type: String })
  label!: string;
  @Prop({ type: String })
  error!: string;
  @Prop({ type: String })
  value!: string;
  @Prop({ type: Array })
  options!: [];
  @Prop({ type: String })
  disabledValue!: string;
  @Prop({ type: Boolean })
  floatingLabel = false;
  @Prop({ type: Boolean })
  disabled = false;
}
