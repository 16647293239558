import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import http from "./plugins/http";
import auth from "./plugins/auth";
import store from "./store";
import i18n from "./i18n";

const app = createApp(App).use(router).use(http).use(auth).use(store).use(i18n);

app.mount("#app");
