
import { Options, Vue } from "vue-class-component";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";
// @ts-expect-error
import { SmartTagz } from "smart-tagz";

import { languagesModule, classgroupsModule } from "@/store";
import { ClassgroupBase } from "@/store/modules/classgroups";
import Input from "@/components/Utils/Input.vue";
import "smart-tagz/dist/smart-tagz.css";

@Options({
  name: "ClassgroupCreate",
  components: {
    Form,
    Field,
    Input,
    SmartTagz,
  },
})
export default class ClassgroupCreate extends Vue {
  schema = yup.object({
    name: yup.string().required("You must input a classgroup name."),
    language: yup
      .number()
      .min(1, "You must select a language pair.")
      .required(),
    students: yup.array().of(yup.string().email()),
  });
  students: Array<string> = [];

  get languages() {
    return languagesModule.languageMapping;
  }

  get languageLevels() {
    return languagesModule.languageLevel;
  }

  levelDescription(level: any) {
    return `${level.title} - ${level.description}`;
  }

  addStudents(students: string[]): void {
    this.students = students;
  }

  processForm(value: any): Promise<void> {
    const languageIdx = value.language - 1;
    const payload: ClassgroupBase = {
      name: value.name,
      languageFromId: this.languages[languageIdx].pair.languageFromId,
      languageToId: this.languages[languageIdx].pair.languageToId,
    };
    return new Promise((resolve) => {
      classgroupsModule.createClassgroup(payload).then((result: any) => {
        resolve();
        if (value.students && value.students.length > 0) {
          classgroupsModule.inviteStudentsByEmail({
            classgroupId: result.data.id,
            emails: value.students,
          });
        }
        this.showModal(result.data);
      });
    });
  }

  showModal(createdClassGroup: any): void {
    Swal.fire({
      title: "Congratulations!",
      text: "Your class group has been successfully created.",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#00d27a",
    }).then(() => {
      this.$router.push({
        name: "ClassgroupExercises",
        params: {
          classgroupId: createdClassGroup.id,
        },
      });
    });
  }
}
