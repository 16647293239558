import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "navbar navbar-light navbar-vertical navbar-expand-xl navbar-card" }
const _hoisted_2 = {
  class: "collapse navbar-collapse",
  id: "navbarVerticalCollapse"
}
const _hoisted_3 = { class: "navbar-vertical-content scrollbar" }
const _hoisted_4 = {
  class: "navbar-nav flex-column mb-3",
  id: "navbarVerticalNav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggler = _resolveComponent("Toggler")!
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createVNode(_component_Toggler, {
        toggled: _ctx.toggledMenu,
        onToggleMenu: _ctx.toggleMenu
      }, null, 8, ["toggled", "onToggleMenu"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, id) => {
              return (_openBlock(), _createBlock(_component_MenuItem, {
                text: item.text,
                icon: item.icon,
                children: item.children,
                href: item.href,
                key: id
              }, null, 8, ["text", "icon", "children", "href"]))
            }), 128))
          ])
        ])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 64))
}