<template>
  <div class="card bg-light" style="max-height: 431px">
    <div class="card-body scrollbar">
      <div class="ps-2 row align-items-center">
        <h4 class="px-0">Search</h4>
        <span class="fw-medium p-0 mb-3 fs--1"
          >Choose one or more and or type a word or phrase</span
        >
        <hr />

        <template v-if="languagePairs && languagePairs.length > 0">
          <div class="row">
            <div class="col p-0">
              <b class>Language Pair</b>
              <select
                class="mt-2 mb-3 form-select"
                v-model="filterModel.languagePair"
              >
                <option
                  v-for="languagePair in languagePairs"
                  :key="languagePair.id"
                  v-text="languagePair.name"
                  :value="languagePair"
                />
              </select>
            </div>
          </div>
          <hr class="mt-0" />
        </template>

        <template v-if="exercisesType.length > 0">
          <div class="row">
            <div class="col p-0">
              <b class>Assignment Type</b>
              <ul class="mt-2 search-list">
                <li v-for="(exerciseType, idx) in exercisesType" :key="idx">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input"
                      :id="`filter-assignment-type-${idx}`"
                      type="checkbox"
                      v-model="filterModel.exerciseType"
                      :value="exerciseType.value"
                    />
                    <label
                      class="form-check-label mb-0"
                      :for="`filter-assignment-type-${idx}`"
                      v-text="exerciseType.name"
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <hr class="mt-0" />
        </template>

        <template v-if="levels.length > 0">
          <div class="row">
            <div class="col p-0">
              <b>Level</b>
              <ul class="mt-2 search-list">
                <li v-for="(level, idx) in levels" :key="idx">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input"
                      :id="`filter-level-${idx}`"
                      type="checkbox"
                      v-model="filterModel.level"
                      :value="level.id"
                    />
                    <label
                      class="form-check-label mb-0"
                      :for="`filter-level-${idx}`"
                      v-text="level.shortDescription"
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <hr class="mt-0" />
        </template>

        <template v-if="categories.length > 0">
          <div class="row">
            <div class="col p-0">
              <b>Categories</b>
              <ul class="mt-2 search-list">
                <li v-for="category in categories" :key="category.id">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input"
                      :id="`filter-category-${category.id}`"
                      type="checkbox"
                      v-model="filterModel.category"
                      :value="category.id"
                    />
                    <label
                      class="form-check-label mb-0"
                      :for="`filter-category-${category.id}`"
                      v-text="category.name"
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <hr class="mt-0" />
        </template>

        <div class="row">
          <div class="col p-0 mb-3">
            <b>Search for a:</b>
            <div class="form-floating">
              <input
                class="form-control form-control-sm mt-2 fs--1"
                id="floatingSearch"
                type="text"
                placeholder="Search a sentence"
                v-model="filterModel.searchText"
              />
              <label class="ps-2 pe-0" for="floatingSearch"
                >Word or Phrase</label
              >
            </div>
          </div>
        </div>

        <template v-if="wordClasses.length > 0">
          <div class="row">
            <div class="col p-0">
              <b class="fs--1">Word of type</b>
              <ul class="mt-2 search-list">
                <li v-for="(wordClass, idx) in wordClasses" :key="idx">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input"
                      :id="`filter-word-type-${idx}`"
                      type="checkbox"
                      v-model="filterModel.wordClass"
                      :value="wordClass.toUpperCase()"
                    />
                    <label
                      class="form-check-label mb-0"
                      :for="`filter-word-type-${idx}`"
                      v-text="wordClass"
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="card-body py-2 text-center shadow-lg rounded">
      <button class="btn btn-sm btn-primary" @click="filter">
        <span class="bi-search" /> Search Now
      </button>
    </div>
  </div>
</template>
<script>
import { Options, mixins } from "vue-class-component";

import { SearchExercisesFilterProps } from "@/components/SearchExercisesFilter/mixins";

@Options({
  name: "SearchExercisesFilterVertical",
})
export default class SearchExercisesFilterVertical extends mixins(
  SearchExercisesFilterProps
) {}
</script>
<style lang="scss" scoped>
.search-list {
  list-style-type: none;
  padding: 0;
}
</style>
