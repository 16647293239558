
import { Vue, Options } from "vue-class-component";
import Toggler from "@/components/Sidebar/Toggler.vue";
import MenuItem from "@/components/Sidebar/MenuItem.vue";

interface Item {
  text: string;
  icon: string;
  href: string;
}

@Options({
  components: {
    Toggler,
    MenuItem,
  },
})
export default class Sidebar extends Vue {
  toggledMenu = false;

  get menu(): Item[] {
    return [
      { text: this.$t("menu.classgroup"), icon: "fa-user-friends", href: "/" },
      /*{
        text: this.$t("menu.my-exercises"),
        icon: "fa-thumbs-up",
        href: "/exercises",
      },*/
      {
        text: this.$t("menu.profile"),
        icon: "fa-user-circle",
        href: "/profile",
      },
      {
        text: this.$t("menu.logout"),
        icon: "fa-sign-out-alt",
        href: "/logout",
      },
      // {
      //   text: this.$t("menu.events"),
      //   icon: "fa-calendar-alt",
      //   href: "/events"
      // },
      // {
      //   text: this.$t("menu.payment-info"),
      //   icon: "fa-credit-card",
      //   href: "/payment-info"
      // },
      // { text: this.$t("menu.help"), icon: "fa-question-circle", href: "/help" },
      // {
      //   text: this.$t("menu.subscribers-contacts"),
      //   icon: "fa-address-book",
      //   href: "/subscribers"
      // }
    ];
  }

  mounted() {
    const sidebar = document.getElementById("navbarVerticalCollapse");
    if (sidebar) {
      sidebar.addEventListener("mouseenter", this.hoverMenuEnter);
      sidebar.addEventListener("mouseleave", this.hoverMenuLeave);
    }
  }

  destroyed() {
    const sidebar = document.getElementById("navbarVerticalCollapse");
    if (sidebar) {
      sidebar.removeEventListener("mouseover", this.hoverMenuEnter);
      sidebar.removeEventListener("mouseleave", this.hoverMenuLeave);
    }
  }

  toggleMenu(): void {
    const html = document.querySelector("html");
    if (html) html.classList.toggle("navbar-vertical-collapsed");
    this.toggledMenu = !this.toggledMenu;
  }

  hoverMenuEnter(): void {
    const html = document.querySelector("html");
    if (html && html.classList.contains("navbar-vertical-collapsed"))
      html.classList.add("navbar-vertical-collapsed-hover");
  }

  hoverMenuLeave(): void {
    const html = document.querySelector("html");
    if (html && html.classList.contains("navbar-vertical-collapsed"))
      html.classList.remove("navbar-vertical-collapsed-hover");
  }
}
