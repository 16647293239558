import { createStore } from "vuex";

import AuthModule from "./modules/auth";
import ConfigModule from "./modules/config";
import ClassgroupsModule from "./modules/classgroups";
import ExercisesModule from "./modules/exercises";
import AssignmentsModule from "./modules/assignments";
import LanguagesModule from "./modules/languages";

export const store = createStore({});

export const authModule = new AuthModule({ store, name: "auth" });
export const configModule = new ConfigModule({ store, name: "config" });
export const languagesModule = new LanguagesModule({ store, name: "language" });
export const assigmentsModule = new AssignmentsModule({
  store,
  name: "assignments",
});
export const classgroupsModule = new ClassgroupsModule({
  store,
  name: "classgroups",
});
export const exercisesModule = new ExercisesModule({
  store,
  name: "exercises",
});

export default store;
