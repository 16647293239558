import { Vue, Options } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

import { exercisesModule, languagesModule } from "@/store";
import { capitalize } from "@/utils/strings";

@Options({
  name: "SearchExercisesFilterProps",
})
export class SearchExercisesFilterProps extends Vue {
  @Prop({ type: Object })
  classGroup!: any;

  @Prop()
  languagePairs!: any;

  private filterModel = {
    exerciseType: [],
    level: [],
    wordClass: [],
    category: [],
    searchText: "",
    languagePair: {} as any,
  };
  private languageFromId = 1;
  private languageToId: any;

  async mounted() {
    if (this.classGroup && Object.keys(this.classGroup).length > 0) {
      this.languageFromId = this.classGroup.languageFromId;
      this.languageToId = this.classGroup.languageToId;
    } else if (this.languagePairs) {
      this.filterModel.languagePair = this.languagePairs[0];
      this.languageFromId = this.filterModel.languagePair.pair.languageFromId;
      this.languageToId = this.filterModel.languagePair.pair.languageToId;
    }
    await exercisesModule.getExerciseCategories(this.languageFromId);
  }

  get exercisesType(): { value: string; name: string }[] {
    return [
      { value: "LISTENING", name: "Stories" },
      { value: "CITY_LIFE", name: "Sentences" },
      { value: "BUDDY_TALK", name: "BuddyTalk" },
    ];
  }

  get levels(): any[] {
    return languagesModule.languageLevel;
  }

  get wordClasses(): string[] {
    return ["noun", "verb", "adjective", "adverb", "phrase"].map(capitalize);
  }

  get categories(): string[] {
    return exercisesModule.exerciseCategories;
  }

  @Emit()
  filter(): {
    exerciseType: string[];
    level: string[];
    wordClass: string[];
    category: string[];
    searchText: string;
    languagePair: any;
  } {
    return this.filterModel;
  }
}
