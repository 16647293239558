
import { Options, Vue } from "vue-class-component";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";

import { authModule } from "@/store";
import Input from "@/components/Utils/Input.vue";

@Options({
  name: "Registration",
  components: {
    Field,
    Form,
    Input,
  },
})
export default class Registration extends Vue {
  schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
    confirmPassword: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref("password")], "Password do not match"),
    tos: yup.boolean().required(),
  });
  errors = {};

  processForm(values: { email: string; password: string }): void {
    authModule
      .register({ email: values.email, password: values.password })
      .then(() => {
        this.showModal();
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  showModal(): void {
    Swal.fire({
      title: "Congratulations!",
      html:
        "Your account has been successfully created.<br>" +
        "<b>Check your email for confirmation message</b>.<br>" +
        "After validation continue completing the profile data",
      icon: "success",
      confirmButtonText: "Continue to Login page",
      confirmButtonColor: "#00d27a",
    }).then(() => {
      this.$router.push({ name: "Login" });
    });
  }
}
