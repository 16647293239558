import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { getBrowserLocale } from "@/utils/i18n";

export const SUPPORT_LOCALES = ["en", "es"];

export async function loadLocaleMessages(i18n: any, locale: string) {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  );

  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

export function setI18nLanguage(i18n: any, locale: string) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  localStorage.setItem("preferredLanguage", locale);

  document?.querySelector("html")?.setAttribute("lang", locale);
}

export function setupI18n(locale = "en") {
  const _locale = getBrowserLocale() || locale;
  const i18n = createI18n({
    locale: _locale,
    fallbackLocale: "en",
  });
  loadLocaleMessages(i18n, _locale);
  setI18nLanguage(i18n, _locale);
  return i18n;
}

const i18n = setupI18n();

export default i18n;
