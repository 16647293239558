import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for", "textContent"]
const _hoisted_2 = ["id", "type", "placeholder", "disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["selected", "textContent"]
const _hoisted_5 = ["value", "selected", "textContent"]
const _hoisted_6 = ["for", "textContent"]
const _hoisted_7 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.floatingLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          textContent: _toDisplayString(_ctx.label)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.type !== 'select')
      ? (_openBlock(), _createBlock(_component_Field, {
          key: 1,
          name: _ctx.name,
          value: _ctx.value
        }, {
          default: _withCtx(({ field }) => [
            _createElementVNode("input", _mergeProps(field, {
              id: _ctx.id,
              class: ["form-control", _ctx.classes],
              type: _ctx.type,
              placeholder: _ctx.placeholder,
              disabled: _ctx.disabled
            }), null, 16, _hoisted_2)
          ]),
          _: 1
        }, 8, ["name", "value"]))
      : (_openBlock(), _createBlock(_component_Field, {
          key: 2,
          id: _ctx.id,
          name: _ctx.name,
          value: "",
          "aria-label": _ctx.placeholder
        }, {
          default: _withCtx(({ value }) => [
            _createElementVNode("select", {
              class: _normalizeClass(["form-select", _ctx.classes]),
              disabled: _ctx.disabled
            }, [
              _createElementVNode("option", {
                value: "",
                disabled: "",
                selected: value,
                textContent: _toDisplayString(_ctx.disabledValue)
              }, null, 8, _hoisted_4),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (element, idx) => {
                return (_openBlock(), _createElementBlock("option", {
                  value: element,
                  selected: value,
                  key: idx,
                  textContent: _toDisplayString(element)
                }, null, 8, _hoisted_5))
              }), 128))
            ], 10, _hoisted_3)
          ]),
          _: 1
        }, 8, ["id", "name", "aria-label"])),
    (_ctx.floatingLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 3,
          for: _ctx.id,
          textContent: _toDisplayString(_ctx.label)
        }, null, 8, _hoisted_6))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "text-danger text-sm",
      textContent: _toDisplayString(_ctx.error)
    }, null, 8, _hoisted_7)
  ]))
}