import supportedLocales from "@/config/supported-locales";

export function supportedLocalesInclude(locale: string) {
  return Object.keys(supportedLocales).includes(locale);
}

export function getBrowserLocale() {
  let lang = localStorage.getItem("preferredLanguage") || "";

  if (!lang) {
    const ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE") > 0)
      // @ts-expect-error
      // This line is specific for IE
      lang = window.navigator.userLanguage;
    else lang = window.navigator.language;
  }

  return lang.trim().split(/-|_/)[0] || lang.trim();
}

export function getSupportedLocales() {
  return Object.entries(supportedLocales).map(([code, name]) => ({
    code,
    name,
  }));
}

export default {
  getBrowserLocale,
  getSupportedLocales,
  supportedLocalesInclude,
};
