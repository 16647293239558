
import { Options, Vue } from "vue-class-component";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";

import { classgroupsModule } from "@/store";
import Input from "@/components/Utils/Input.vue";
import { Prop } from "vue-property-decorator";

@Options({
  name: "ModalCreateStudent",
  components: {
    Form,
    Field,
    Input,
  },
})
export default class ModalCreateStudent extends Vue {
  @Prop()
  private classgroupId!: number;

  schema = yup.object({
    email: yup.string().email().required(),
  });
  errors = {};

  onSubmit(values: any, { resetForm }: any): Promise<void> {
    const student = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
    };
    return new Promise((resolve) => {
      classgroupsModule
        .createStudent({
          classgroupId: this.classgroupId,
          student: student,
        })
        .then(() => {
          resolve();
          Swal.fire({
            title: "Congratulations!",
            text: "You have invited your student(s) successfully.",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#00d27a",
          }).then(() => {
            this.$emit("close");
          });
        })
        .finally(() => {
          resetForm();
        });
    });
  }
}
