import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nav-item" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "nav-link-icon" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["href", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, { to: _ctx.href }, {
      default: _withCtx(({ href, isActive }) => [
        _createElementVNode("a", _mergeProps({
          class: ["nav-link", { 'dropdown-indicator': _ctx.children, active: isActive }]
        }, _ctx.getAttrs(), { href: href }), [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass(["fas", _ctx.icon])
              }, null, 2)
            ]),
            _createElementVNode("span", {
              class: "nav-link-text",
              textContent: _toDisplayString(_ctx.text)
            }, null, 8, _hoisted_5)
          ])
        ], 16, _hoisted_2)
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.children)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: "nav collapse",
          id: _ctx.getAttrs()['id-collapsable'],
          "data-bs-parent": "#navbarVerticalCollapse"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (child) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item",
              key: child.text
            }, [
              _createVNode(_component_router_link, { to: _ctx.href }, {
                default: _withCtx(({ href, isActive }) => [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", { active: isActive }]),
                    href: href,
                    textContent: _toDisplayString(child.text)
                  }, null, 10, _hoisted_7)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ], 8, _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}