import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { http } from "@/plugins/http";
import { clearCookies, clearLocalStorage } from "@/utils/cookies";

export interface User {
  profileId: number;
  email: string;
  name: string;
}

export interface Auth {
  isLoggedIn: boolean;
  user: User;
}

@Module({ generateMutationSetters: true })
export default class AuthModule extends VuexModule {
  // state
  private state: Auth = {
    isLoggedIn: false,
    user: {} as User,
  };

  // getters
  get isLoggedIn(): boolean {
    const auth = localStorage.getItem("auth");
    if (auth) {
      return JSON.parse(auth).isLoggedIn;
    }
    return this.state.isLoggedIn;
  }

  // mutations
  @Mutation
  public setLogin(data: Auth): void {
    localStorage.setItem("auth", JSON.stringify(data));
    this.state = { ...data };
  }

  @Mutation
  public setLogout(): void {
    clearCookies();
    clearLocalStorage();
    this.state = {
      isLoggedIn: false,
      user: {} as User,
    };
  }

  // actions
  @Action
  public async login(data: { email: string; password: string }): Promise<void> {
    try {
      const resp = await http.post("/teachers/auth/logins", data);
      if (resp.status === 200) {
        return this.setLogin({
          isLoggedIn: true,
          user: { profileId: 1, email: data.email, name: "" },
        });
      } else {
        throw new Error("Error loggin in. Try again");
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error loggin in. Try again");
    }
  }

  @Action
  public async logout(): Promise<void> {
    try {
      const resp = await http.post("/teachers/auth/logout");

      if (resp.status === 200) {
        return this.setLogout();
      } else {
        throw new Error("Error loggin out. Try again");
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error loggin out. Try again");
    }
  }

  @Action
  public async register(data: {
    email: string;
    password: string;
  }): Promise<void> {
    try {
      const resp = await http.post("/teachers", data);
      if (resp.status === 200) {
        return;
      } else {
        throw new Error("Error registering. Try again");
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error registering. Try again");
    }
  }
}
