
import { Vue, Options } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import List from "list.js";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import flatpickr from "flatpickr";

import { Classgroup } from "@/store/modules/classgroups";
import { exercisesModule } from "@/store";
import { Exercise } from "@/store/modules/exercises";

import Rating from "@/components/Utils/Rating.vue";
import ModalExerciseDetails from "@/components/Dashboard/ModalExerciseDetails.vue";
import ModalAssignExercisesToClassgroups from "@/components/Dashboard/ModalAssignExercisesToClassgroups.vue";
import SearchExercises from "@/components/SearchExercisesFilter/Index.vue";

@Options({
  name: "DataTableExercises",
  components: {
    Rating,
    ModalExerciseDetails,
    ModalAssignExercisesToClassgroups,
    SearchExercises,
  },
})
export default class DataTableExercises extends Vue {
  @Prop()
  private classGroup!: Classgroup;
  @Prop()
  private classGroups!: Classgroup[];
  @Prop()
  private languagePair!: any;
  @Prop()
  private exercises!: Exercise[];
  @Prop()
  private pagination!: any;

  $refs!: {
    modalExerciseDetails: any;
    modalAssignExerciseToClassgroups: any;
  };

  private dataListOptions = {
    valueNames: [
      "difficult",
      "exerciseType",
      "level",
      "gender",
      "accent",
      "assigned",
      "liked",
      "notes",
    ],
    page: 8,
  };
  private dataTable: any;
  private exercisesToAssign: number[] = [];
  private selectedExercise: any = null;
  private selectedExerciseIdx = 0;

  get pages(): number[] {
    const pages = Math.ceil(this.pagination.count / this.pagination.limit);
    let result: any = [];
    let activePage = 1;

    for (let i = 1; i <= pages; i++) {
      let active =
        this.pagination.offset == 0 && i == 1
          ? true
          : (this.pagination.offset - 1) / this.pagination.limit + 1 == i;
      result.push({
        number: i,
        active: active,
      });
      if (active) activePage = i;
    }

    if (result.length > 5) {
      if (activePage < 4) {
        return result.slice(0, 5);
      } else {
        return result.slice(activePage - 3, activePage + 2);
      }
    }

    return result;
  }

  get languageFromId() {
    if (this.classGroup) {
      return this.classGroup.languageFromId;
    } else if (this.languagePair) {
      return this.languagePair.languageFromId;
    } else {
      return 1;
    }
  }

  get languageToId() {
    if (this.classGroup) {
      return this.classGroup.languageToId;
    } else if (this.languagePair) {
      return this.languagePair.languageToId;
    } else {
      return 2;
    }
  }

  mounted(): void {
    this.dataTable = new List("contentExercisesTable", this.dataListOptions);
    flatpickr(".datetimepicker", {});
  }

  @Emit()
  changePage(pageNumber: number): number {
    if (pageNumber === 1) return 0;

    return (pageNumber - 1) * 8 + 1;
  }

  showExerciseDetails(exercise: any, idx: number): void {
    this.selectedExercise = exercise;
    this.selectedExerciseIdx = idx;
  }

  closeModalExerciseDetails(): void {
    this.selectedExercise = null;
    this.selectedExerciseIdx = -1;
  }

  showNextExerciseDetails() {
    if (this.selectedExerciseIdx < this.exercises.length - 1) {
      this.selectedExerciseIdx = this.selectedExerciseIdx + 1;
      this.selectedExercise = this.exercises[this.selectedExerciseIdx];
    }
  }

  showPrevExerciseDetails() {
    if (this.selectedExerciseIdx > 0) {
      this.selectedExerciseIdx = this.selectedExerciseIdx - 1;
      this.selectedExercise = this.exercises[this.selectedExerciseIdx];
    }
  }

  addExerciseToClassgroup(exercise_id: number): void {
    this.exercisesToAssign.push(exercise_id);
  }

  removeExerciseFromClassgroup(exercise_id: number): void {
    const idx = this.exercisesToAssign.indexOf(exercise_id);
    this.exercisesToAssign.splice(idx, 1);
  }

  showModalAssignExercisesToGlassgroups(): void {
    const modal = Modal.getOrCreateInstance(
      this.$refs.modalAssignExerciseToClassgroups.$el
    );
    modal.show();
  }

  closeModalAssignExercisesToGlassgroups(): void {
    const modal = Modal.getOrCreateInstance(
      this.$refs.modalAssignExerciseToClassgroups.$el
    );
    modal.hide();
  }

  async addExercisesToClassgroup(data: {
    classgroups: any[];
    availableFrom: string;
    deadline: string;
  }): Promise<void> {
    try {
      const assignedExercises = await exercisesModule.prepareExercises({
        classgroups: data.classgroups,
        availableFrom: data.availableFrom,
        deadline: data.deadline,
        exercises: this.exercisesToAssign,
      });

      if (assignedExercises.success) {
        this.closeModalAssignExercisesToGlassgroups();
        this.exercisesToAssign = [];

        Swal.fire({
          title: "Congratulations!",
          text: "Exercises were added successfully to the class group.",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#00d27a",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
}
