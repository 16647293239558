
import { Vue, Options } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { Modal } from "bootstrap";

import { exercisesModule } from "@/store";
import Rating from "@/components/Utils/Rating.vue";

@Options({
  name: "ModalExerciseDetails",
  components: {
    Rating,
  },
})
export default class ModalExerciseDetails extends Vue {
  @Prop()
  exercise!: any;

  @Prop()
  languageFromId!: any;

  @Prop()
  languageToId!: any;

  @Prop()
  added!: boolean;

  @Prop()
  show = false;

  @Prop()
  first = false;

  @Prop()
  last = false;

  content: any = null;

  $refs!: {
    modalExerciseDetails: any;
  };

  @Emit()
  add() {
    return this.exercise.id;
  }

  @Emit()
  remove() {
    return this.exercise.id;
  }

  @Emit("close")
  closeModal(): void {
    this.content = null;
    const modal = Modal.getOrCreateInstance(this.$refs.modalExerciseDetails);
    modal.hide();
  }

  async updated(): Promise<void> {
    if (this.show && this.content == null) {
      await this.$nextTick(async () => {
        this.content = await exercisesModule.getExerciseDetails({
          languageFromId: this.languageFromId,
          languageToId: this.languageToId,
          exercise: this.exercise,
        });
      });
      const modal = Modal.getOrCreateInstance(this.$refs.modalExerciseDetails);
      modal.show();
    }
  }
}
