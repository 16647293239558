import axios from "axios";

export const http = axios.create({
  baseURL:
    process.env.VUE_APP_API_URL || "https://staging.api.latudio.com/api/1.0",
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// @ts-expect-error
export default (app) => {
  app.axios = http;
  app.$http = http;

  app.config.globalProperties.axios = http;
  app.config.globalProperties.$http = http;
};
