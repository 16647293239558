
import { Vue, Options } from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

import Choices from "choices.js";

import { Classgroup } from "@/store/modules/classgroups";

@Options({
  name: "ModalAssignExercisesToClassgroups",
})
export default class ModalAssignExercisesToClassgroups extends Vue {
  @Prop()
  classGroups!: Classgroup[];
  @Prop()
  classGroup!: Classgroup;

  choices: any;

  @Prop()
  availableFrom: any;

  @Prop()
  deadline: any;

  $refs!: {
    selectClassgroups: HTMLSelectElement;
  };

  mounted(): void {
    this.initChoices();
  }

  initChoices(): void {
    this.choices = new Choices(this.$refs.selectClassgroups, {
      position: "top",
    });
    this.choices.setChoices(this.classGroups, "id", "name", false);
    if (this.classGroup) {
      this.choices.setChoiceByValue(this.classGroup.id);
    }
  }

  @Emit()
  assign() {
    return {
      classgroups: this.choices.getValue(),
      availableFrom: this.availableFrom,
      deadline: this.deadline,
    };
  }

  @Emit("close")
  closeModal(): void {
    this.choices.removeActiveItems();
  }
}
