
import { Vue, Options } from "vue-class-component";
import Loading from "@/components/Utils/Loading.vue";

import { assigmentsModule } from "@/store";
import { exercisesModule } from "@/store";
import { Prop } from "vue-property-decorator";
import { Classgroup } from "@/store/modules/classgroups";
import { ListOptions } from "list.js";
import { Assignment } from "@/store/modules/assignments";
import FormatDate from "../Utils/FormatDate.vue";

@Options({
  name: "DataTableAssignments",
  components: {
    Loading,
    FormatDate,
  },
})
export default class DataTableAssignments extends Vue {
  @Prop()
  private classgroup: Classgroup = {} as Classgroup;

  dataListOptions: ListOptions = {
    valueNames: ["availableFrom", "deadline", "notesForMe", "notesForStudents"],
    page: 2,
    pagination: {
      paginationClass: "pagination",
    },
  };

  private dataTable: any;

  get isLoading(): boolean {
    return assigmentsModule.assignmentsLoading;
  }

  get classgroupAssignments(): Assignment[] {
    return assigmentsModule.assignmentsForClassgroup(this.classgroup.id);
  }

  async created(): Promise<void> {
    await assigmentsModule.fetchAssignmentsForClassgroup(this.classgroup.id);
    // TODO: Pagination - or I do not know why it was actually here
    // this.dataTable = new List("assignments-data-table", this.dataListOptions);
  }

  formatExerciseTitle(id: number): string {
    let exercise = exercisesModule.getExerciseById(id);
    return `${exercise.difficult} / ${exercise.type} / ${exercise.level} / ${exercise.gender} / ${exercise.accent}`;
  }
}
