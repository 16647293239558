
import { Options, Vue } from "vue-class-component";
import { Field, Form } from "vee-validate";
import * as yup from "yup";

import { authModule } from "@/store";
import Input from "@/components/Utils/Input.vue";

import { FormLogin } from "./login";

@Options({
  name: "Login",
  components: {
    Field,
    Form,
    Input,
  },
})
export default class Login extends Vue {
  schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
    rememberMe: yup.boolean(),
  });
  errors = {};
  fetchUser = true;

  processForm(values: FormLogin): void {
    authModule
      .login({ email: values.email, password: values.password })
      .then(() => {
        this.$router.push({ name: "Home" });
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
