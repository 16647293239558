import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import i18n, { setI18nLanguage } from "@/i18n";
import { getBrowserLocale } from "@/utils/i18n";

export interface Config {
  preferredLanguage: string;
}

@Module({ generateMutationSetters: true })
export default class ConfigModule extends VuexModule {
  // state
  private _state: Config = {
    preferredLanguage: getBrowserLocale(),
  };

  // getters
  get config(): Config {
    return this._state;
  }

  // mutations
  @Mutation
  updatePreferredLanguage(value: string) {
    this._state.preferredLanguage = value;
    setI18nLanguage(i18n, value);
    localStorage.setItem("preferredLanguage", value);
  }

  // actions
  @Action
  public setPreferredLanguage(value: string) {
    this.updatePreferredLanguage(value);
  }
}
