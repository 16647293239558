
import { Options, Vue } from "vue-class-component";
import { Form, FieldArray } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";

import { classgroupsModule } from "@/store";
import Input from "@/components/Utils/Input.vue";

@Options({
  name: "ClassgroupStudentsCreate",
  components: {
    Form,
    FieldArray,
    Input,
  },
})
export default class ClassgroupStudentsCreate extends Vue {
  schema = yup.object({
    students: yup.array().of(
      yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().email().required(),
      })
    ),
  });
  defaultNewStudentValue = {
    firstName: "",
    lastName: "",
    email: "",
  };
  formInitialValues = {
    students: [this.defaultNewStudentValue],
  };
  errors = {};

  onSubmit(values: any): void {
    const students = values.students.map((student: any) => ({
      name: `${student.firstName} ${student.lastName}`,
      email: student.email,
    }));

    this.inviteStudents(students);
  }

  async inviteStudents(students: any): Promise<void> {
    const resp = await classgroupsModule.createStudents({
      classgroupId: this.$route.params.classgroupId,
      students: students,
    });

    if (resp.success) {
      Swal.fire({
        title: "Congratulations!",
        text: "You have invited your student(s) successfully.",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#00d27a",
      }).then(() => {
        this.$router.push({
          name: "ClassgroupExercises",
          params: {
            classgroupId: this.$route.params.classgroupId,
          },
        });
      });
    }
  }
}
