
import { Vue, Options } from "vue-class-component";
import Lottie from "lottie-web";

import InfiniteLoop from "@/assets/img/animated-icons/infinite-loop.json";

@Options({
  name: "Loading",
})
export default class Loading extends Vue {
  $refs!: {
    loadingContainer: any;
  };
  mounted(): void {
    Lottie.loadAnimation({
      container: this.$refs.loadingContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: InfiniteLoop,
    });
  }
}
